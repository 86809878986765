// Here you can add other styles
.c-sidebar {
  .nav-logout {
    cursor: pointer;
    bottom: 0;
    position: absolute;
  }
}

.c-pointer {
  cursor: pointer;
}

// Data view
.data-view-index {
  .data-view-info {
    &.row {
      line-height: 25px;
    }

    span {
      font-size: 16px;
    }
  }

  .data-view-graph {
    #DataViewGraph {
      width: 100%;
      // height: calc(100vh - 340px);
      height: 400px;
    }

    .dygraph-label-rotate-right {
      text-align: center;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
    }

    // .dygraph-axis-label-x {
    //   display: none;
    // }
  }
}

button.btn-hover {
  color: #3399ff;
  text-decoration: underline dotted;
  cursor: help;

  &:disabled {
    color: #00000050;
    text-decoration: none;
    pointer-events: none;
  }
}

// Not allowed style
.not-allowed {
  opacity: 0.2;
  cursor: not-allowed;
}

.invalid-feedback-custom {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}

.common-data-table {
  overflow-wrap: anywhere;
}

.c-siderbar-tearms-of-service {
  position: absolute;
  width: 100%;
  bottom: 0;
}